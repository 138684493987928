import styled from 'styled-components'
import { FilterConfig, SortDirection } from '@netpurpose/types'
import { colors } from '../../../themes/light/colors'
import CaretDownIcon from '../../Icon/icons/CaretDown'
import CaretUpIcon from '../../Icon/icons/CaretUp'
import { Tooltip } from '../../Tooltip'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.architecture3};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
  }
`

const getNextDirection = (activeDirection: SortDirection | undefined) => {
  if (!activeDirection) {
    return SortDirection.DESC
  }
  if (activeDirection === SortDirection.DESC) {
    return SortDirection.ASC
  }
  return null
}

const getTooltipText = (activeDirection: SortDirection | undefined) => {
  if (!activeDirection) {
    return 'Click to sort descending'
  }
  if (activeDirection === SortDirection.DESC) {
    return 'Click to sort ascending'
  }
  return 'Click to clear sorting'
}

export const SortIcon = <Model,>({
  dataIndex,
  filterConfig,
}: {
  dataIndex: keyof Model
  filterConfig: FilterConfig<Model>
}) => {
  const { sorting, setSortDirection } = filterConfig
  const activeDirection = sorting[dataIndex]

  const getColor = (iconDirection: SortDirection) =>
    iconDirection === activeDirection ? 'black' : colors.typeTertiary

  const handleClick = () => {
    const direction = getNextDirection(activeDirection)
    return setSortDirection(dataIndex, direction)
  }

  return (
    <Tooltip title={getTooltipText(activeDirection)} $minimalPadding placement="right">
      <Container onClick={handleClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={colors.typeTertiary}
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Sort icon"
        >
          <CaretUpIcon color={getColor(SortDirection.DESC)} />
          <CaretDownIcon color={getColor(SortDirection.ASC)} />
        </svg>
      </Container>
    </Tooltip>
  )
}
